import * as React from 'react';
import { FONT_COLOURS } from '@belong/themes';
import { IPageTitle, progressColourVariants } from '@belong/types';
import * as Title from './PageTitle.styles';
import { Display } from '../../styles/Typography/Display';
import Container from '../../layout/Container';
import { Column, Columns } from '../../ui';

const PageTitle: React.FC<IPageTitle> = ({
  heading,
  backgroundColor = 'BLACK',
  backgroundImage,
  variant,
  hasLoadingBar = false,
  hasCompletedLoading = false
}: IPageTitle) => {
  const isSmall = variant === 'Small';
  const hasLightContent = backgroundColor === 'BLACK' ? { hasColor: FONT_COLOURS.LIGHT } : undefined;
  const completedLoadingProps = hasCompletedLoading ? { isThin: true, completed: 100 } : {};

  return (
    <Title.OuterWrapper backgroundColor={backgroundColor} isSmall={isSmall}>
      <Container>
        <Columns alignX="center">
          <Column width={{ xl: '5/6' }}>
            <Title.Content>
              {!backgroundImage && heading && (
                <Display {...hasLightContent} as="h1">
                  {heading}
                </Display>
              )}
            </Title.Content>
          </Column>
        </Columns>
      </Container>
      {backgroundImage && (
        <Title.Image
          src={{
            sm: [backgroundImage.src, { w: 742, h: isSmall ? 112 : 168, fit: 'fill', f: 'face' }],
            md: [backgroundImage.src, { w: 1012, h: isSmall ? 152 : 248, fit: 'fill', f: 'face' }],
            xl: [backgroundImage.src, { w: 1440, h: isSmall ? 200 : 384, fit: 'fill', f: 'face' }]
          }}
          alt={backgroundImage.alt}
          contentType={backgroundImage.contentType}
        />
      )}
      {hasLoadingBar && (
        <Title.ProgressBar {...completedLoadingProps} animateBackground colour={progressColourVariants.foil_slick} />
      )}
    </Title.OuterWrapper>
  );
};

PageTitle.displayName = 'PageTitle';

export default PageTitle;
