import * as React from 'react';
import { Section, PageTitle } from '@belong/ui-components';
import { ISectionPageTitle } from '@belong/contentful';

const SectionPageTitle: React.FC<ISectionPageTitle> = ({ id, identifier, ...props }: ISectionPageTitle) => {
  return (
    <Section
      data-contentid={id}
      data-identifier={identifier}
      isFullWidth
      isPadded={false}
      data-testid="section-page-title"
    >
      <PageTitle {...props} />
    </Section>
  );
};

export default SectionPageTitle;
