import { COLOURS, Z_INDEX, media, mediaMap } from '@belong/themes';
import styled, { css } from 'styled-components';
import { BackgroundColor, StyledCSS } from '@belong/types';
import { LinearProgress } from '@belong/ui-core';
import { PictureContainer } from '../../media/PictureContainer';

const VARIANT_SMALL_PADDING = {
  md: '5.6rem',
  lg: '7.2rem'
};

const VARIANT_LARGE_VALUES = {
  md: 5.6,
  lg: 8
};

interface IOuterWrapperProps {
  backgroundColor: BackgroundColor;
  isSmall: boolean;
}

export const OuterWrapper = styled.div<IOuterWrapperProps>`
  position: relative;
  text-align: center;

  ${({ backgroundColor, isSmall }: IOuterWrapperProps): StyledCSS<IOuterWrapperProps> => css`
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;
    background-color: ${backgroundColor === 'BLACK' ? `${COLOURS.BLACK}` : `${COLOURS.WHITE}`}};

    ${
      isSmall &&
      mediaMap(
        VARIANT_SMALL_PADDING,
        (padding: string) => css`
          padding-top: ${padding};
          padding-bottom: ${padding};
        `
      )
    }

    ${
      !isSmall &&
      css`
        ${mediaMap(
          VARIANT_LARGE_VALUES,
          (value: number) => css`
            padding-top: ${value}rem;
            padding-bottom: ${3 * value}rem;
            margin-bottom: ${-2 * value}rem;
          `
        )}
        z-index: ${Z_INDEX.PAGE_TITLE};
      `
    }
  `}
`;

export const Content = styled.div`
  position: relative;
  z-index: 1;
  min-height: 3.6rem;

  ${media('md')`
    min-height: 4.8rem;
 `}
`;

export const Image = styled(PictureContainer)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ProgressBar = styled(LinearProgress)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;
